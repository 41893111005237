// src/components/Footer.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookF,
  faTwitter,
  faInstagram,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';

const Footer = () => (
  <footer className="bg-gray-900 dark:bg-gray-800 text-white py-16 transition-colors duration-300">
    <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
        {/* Sección 1: Sobre la empresa */}
        <div>
          <h4 className="text-xl font-semibold mb-4 text-white dark:text-gray-200 transition-colors duration-300">
            Sobre 2dev4u
          </h4>
          <p className="text-gray-400 dark:text-gray-400">
            En 2dev4u ofrecemos soluciones tecnológicas personalizadas para
            pequeñas y medianas empresas, ayudando a impulsar su crecimiento
            mediante herramientas innovadoras.
          </p>
        </div>

        {/* Sección 2: Navegación */}
        <div>
          <h4 className="text-xl font-semibold mb-4 text-white dark:text-gray-200 transition-colors duration-300">
            Navegación
          </h4>
          <ul className="space-y-2">
            <li>
              <a
                href="#features"
                className="hover:text-blue-500 dark:hover:text-blue-400 transition-colors duration-300"
              >
                Características
              </a>
            </li>
            <li>
              <a
                href="#testimonials"
                className="hover:text-blue-500 dark:hover:text-blue-400 transition-colors duration-300"
              >
                Testimonios
              </a>
            </li>
            <li>
              <a
                href="#contact"
                className="hover:text-blue-500 dark:hover:text-blue-400 transition-colors duration-300"
              >
                Contacto
              </a>
            </li>
          </ul>
        </div>

        {/* Sección 3: Redes sociales */}
        <div>
          <h4 className="text-xl font-semibold mb-4 text-white dark:text-gray-200 transition-colors duration-300">
            Síguenos
          </h4>
          <div className="flex space-x-4 text-xl">
            <a
              href="#"
              className="text-gray-400 hover:text-blue-500 dark:hover:text-blue-400 transition-colors duration-300"
            >
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
            <a
              href="#"
              className="text-gray-400 hover:text-blue-500 dark:hover:text-blue-400 transition-colors duration-300"
            >
              <FontAwesomeIcon icon={faTwitter} />
            </a>
            <a
              href="#"
              className="text-gray-400 hover:text-blue-500 dark:hover:text-blue-400 transition-colors duration-300"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a
              href="#"
              className="text-gray-400 hover:text-blue-500 dark:hover:text-blue-400 transition-colors duration-300"
            >
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
          </div>
        </div>

        {/* Sección 4: Suscripción al boletín */}
        <div>
          <h4 className="text-xl font-semibold mb-4 text-white dark:text-gray-200 transition-colors duration-300">
            Suscríbete
          </h4>
          <p className="text-gray-400 mb-4 dark:text-gray-400">
            Recibe las últimas noticias y actualizaciones de 2dev4u.
          </p>
          <form>
            <input
              type="email"
              className="w-full p-2 mb-4 rounded-lg bg-gray-800 dark:bg-gray-700 text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors duration-300"
              placeholder="Tu correo electrónico"
            />
            <button
              type="submit"
              className="w-full py-2 bg-blue-600 dark:bg-blue-500 hover:bg-blue-700 dark:hover:bg-blue-600 rounded-lg text-white font-semibold transition-colors duration-300"
            >
              Suscribirse
            </button>
          </form>
        </div>
      </div>

      {/* Separador */}
      <div className="border-t border-gray-700 dark:border-gray-600 mt-12 pt-8 text-center">
        <p className="text-gray-400 dark:text-gray-400">
          &copy; {new Date().getFullYear()} 2dev4u. Todos los derechos
          reservados.
        </p>
        <p className="mt-2 text-gray-500 dark:text-gray-300">
          Hecho con ❤️ por{' '}
          <a
            href="https://2dev4u.es/"
            className="hover:underline text-blue-400 dark:text-blue-300 transition-colors duration-300"
            target="_blank"
            rel="noopener noreferrer"
          >
            2dev4u
          </a>
        </p>
      </div>
    </div>
  </footer>
);

export default Footer;
