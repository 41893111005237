// src/components/Features.js
import React from 'react';
import Lottie from 'react-lottie-player';
import './Features.css';
import conversaIcon from '../img/conversa-1.svg';
import conversaIcon2 from '../img/conversa-2.svg';
import conversaIcon3 from '../img/conversa-3.svg';
import conversaIcon4 from '../img/conversa-4.svg'; // importamos conversa-4.svg

const Features = () => {
  const featuresData = [
    {
      id: 1,
      title: 'IA adaptada a tu empresa',
      description:
        'Asistentes de IA personalizados que responden con precisión usando la información de tu negocio.',
      icon: conversaIcon,
      bgColor: '#B0A0FF',
      isSVG: true,
    },
    {
      id: 2,
      title: 'Atención 24/7',
      description:
        'Brinda soporte continuo a tus clientes, mejorando su satisfacción y fidelidad.',
      icon: conversaIcon2,
      bgColor: '#F0A2FD',
      isSVG: true,
    },
    {
      id: 3,
      title: 'Integración Multicanal',
      description:
        'Atiende a tus clientes en tu página web y WhatsApp fácilmente.',
      icon: conversaIcon3,
      bgColor: '#FEB591',
      isSVG: true,
    },
    {
      id: 4,
      title: 'Análisis Avanzado',
      description:
        'Obtén insights detallados sobre el comportamiento de tus clientes y mejora tus estrategias.',
      icon: conversaIcon4, // asignamos conversa-4.svg aquí
      bgColor: '#c6f15c',
      isSVG: true, // cambiamos a true para usar el SVG
    },
  ];

  return (
    <section
      id="features"
      className="relative bg-white dark:bg-gray-900 transition-colors duration-300"
    >
      <div className="max-w-5xl mx-4 md:mx-auto">
        <div className="container">
          <ul id="cards">
            {featuresData.map((feature, index) => {
              const isEven = index % 2 === 0;
              return (
                <li
                  key={feature.id}
                  className={`card ${isEven ? 'left' : 'right'}`}
                  id={`card${index + 1}`}
                  style={{ '--index': index + 1 }}
                >
                  <div
                    className="card-body"
                    style={{ '--bgColor': feature.bgColor }}
                  >
                    <div
                      className={`flex flex-col md:flex-row items-center ${
                        isEven ? '' : 'md:flex-row-reverse'
                      }`}
                    >
                      {/* Comprobamos si la feature usa SVG o animación */}
                      <div className="hidden md:flex justify-center mb-0 md:mx-6 w-full md:w-1/2">
                        {feature.isSVG ? (
                          <img
                            src={feature.icon}
                            alt="Feature Icon"
                            className="w-full h-auto max-w-[300px] sm:max-w-[350px]"
                            draggable="false"
                          />
                        ) : (
                          <Lottie
                            loop
                            animationData={feature.icon}
                            play
                            className="w-full h-auto max-w-[200px] sm:max-w-[250px]"
                          />
                        )}
                      </div>

                      {/* Texto */}
                      <div className="w-full md:w-1/2">
                        <h4 className="text-5xl font-semibold mb-2 text-gray-900 transition-colors duration-300">
                          {feature.title}
                        </h4>
                        <p className="max-w-md text-xl pt-4 md:pb-0 pb-24 transition-colors duration-300">
                          {feature.description}
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Features;
