// src/components/Chatbot.js
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import logo from '../img/logo-IA4u-w.svg';
import chatIcon from '../img/chat-icon-w.svg';
import { IoCloseSharp } from 'react-icons/io5';
import { FiSend } from 'react-icons/fi';

function Chatbot() {
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [animateChat, setAnimateChat] = useState(false);

  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages, isTyping]);

  const sendMessage = async () => {
    if (input.trim() === '') return;

    const userMessage = { sender: 'user', text: input };
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setInput('');
    setIsTyping(true);

    inputRef.current?.focus();

    try {
      const backendUrl =
        process.env.REACT_APP_BACKEND_URL || 'http://localhost:8000';

      const response = await axios.post(`${backendUrl}/chat`, {
        content: input,
      });

      const botMessage = { sender: 'bot', text: response.data.response };
      setMessages((prevMessages) => [...prevMessages, botMessage]);
    } catch (error) {
      console.error(error);
      const errorMessage = {
        sender: 'bot',
        text: 'Lo siento, ha ocurrido un error.',
      };
      setMessages((prevMessages) => [...prevMessages, errorMessage]);
    } finally {
      setIsTyping(false);
    }
  };

  const handleOpenChat = () => {
    setIsOpen(true);
    setTimeout(() => {
      setAnimateChat(true);
    }, 10);
  };

  const handleCloseChat = () => {
    setAnimateChat(false);
    setTimeout(() => {
      setIsOpen(false);
    }, 300); // Debe coincidir con la duración de la animación
  };

  return (
    <div
      className={`fixed bottom-6 ${
        isOpen ? 'inset-x-0 flex justify-center' : 'right-6'
      } md:right-6 md:left-auto md:inset-x-auto`}
    >
      {isOpen ? (
        <div
          className={`w-full max-w-md mx-2 md:w-96 md:mx-0 h-[600px] bg-white dark:bg-gray-800 rounded-xl shadow-lg flex flex-col transform transition-all duration-300 ${
            animateChat
              ? 'translate-y-0 opacity-100'
              : 'translate-y-4 opacity-0'
          }`}
        >
          {/* Encabezado */}
          <div className="bg-blue-600 dark:bg-gray-800 text-white p-4 rounded-t-xl flex justify-between items-center transition-colors duration-300">
            <div className="flex items-center">
              <img
                src={logo}
                alt="Logo"
                className="w-14 mr-2"
                draggable="false"
              />
            </div>
            <button
              onClick={handleCloseChat}
              className="text-2xl hover:text-gray-300 transition-colors duration-300"
            >
              <IoCloseSharp />
            </button>
          </div>

          {/* Área de mensajes */}
          <div className="flex-grow p-4 bg-gray-50 dark:bg-gray-700 overflow-y-auto transition-colors duration-300">
            {messages.map((msg, index) => (
              <div
                key={index}
                className={`mb-4 flex ${
                  msg.sender === 'user' ? 'justify-end' : 'justify-start'
                }`}
              >
                <div
                  className={`max-w-xs px-4 py-2 rounded-lg shadow ${
                    msg.sender === 'user'
                      ? 'bg-blue-600 text-white'
                      : 'bg-gray-200 dark:bg-gray-600 text-gray-800 dark:text-gray-200'
                  } transition-colors duration-300`}
                >
                  {msg.text}
                </div>
              </div>
            ))}
            {isTyping && (
              <div className="mb-4 flex justify-start">
                <div className="max-w-xs px-4 py-2 rounded-lg shadow bg-gray-200 dark:bg-gray-600 text-gray-800 dark:text-gray-200 transition-colors duration-300">
                  Escribiendo...
                </div>
              </div>
            )}
            <div ref={messagesEndRef} />
          </div>

          {/* Campo de entrada */}
          <div className="p-4 bg-white dark:bg-gray-800 border-t dark:border-gray-700 flex items-center rounded-b-xl transition-colors duration-300">
            <input
              type="text"
              className="flex-grow border border-gray-300 dark:border-gray-600 rounded-full px-4 py-2 mr-2 bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-600 transition-colors duration-300"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyPress={(e) => (e.key === 'Enter' ? sendMessage() : null)}
              placeholder="Escribe tu mensaje..."
              ref={inputRef}
            />
            <button
              className={`bg-blue-600 dark:bg-blue-500 hover:bg-blue-700 dark:hover:bg-blue-600 text-white p-3 rounded-full transition-colors duration-300 ${
                input.trim() === '' ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              onClick={sendMessage}
              disabled={input.trim() === ''}
            >
              <FiSend className="w-5 h-5" />
            </button>
          </div>
        </div>
      ) : (
        <button
          onClick={handleOpenChat}
          className="bg-blue-600 dark:bg-blue-500 text-white p-4 rounded-full shadow-lg hover:bg-blue-700 dark:hover:bg-blue-600 transition-colors duration-300"
        >
          <img src={chatIcon} alt="Abrir chat" className="w-8 h-8" />
        </button>
      )}
    </div>
  );
}

export default Chatbot;
