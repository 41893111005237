// src/components/HeroSection.js
import React from 'react';
import Lottie from 'react-lottie-player';
import animationData from '../animations/chatbot-face.json'; // Animación para Light Mode
import animationDataWhite from '../animations/chatbot-face-white.json'; // Animación para Dark Mode

const HeroSection = ({ darkMode }) => (
  <section className="bg-gray-100 dark:bg-gray-800 text-center md:text-left pt-12 pb-2 md:px-28 md:py-28 px-4 transition-colors duration-300">
    <div className="max-w-5xl mx-auto grid md:grid-cols-[3fr,2fr] md:gap-28 gap-0 items-center">
      {/* Columna de texto y botones */}
      <div>
        <h2 className="text-4xl md:text-6xl font-bold mb-4 text-gray-900 dark:text-white transition-colors duration-300">
          El chatbot{' '}
          <span className="bg-gradient-to-r from-blue-400 via-purple-500 to-pink-500 bg-[length:400%_400%] animate-gradient text-transparent bg-clip-text">
            perfecto
          </span>{' '}
          para tu negocio
        </h2>
        <p className="text-lg md:text-xl my-8 mx-4 md:mx-0 text-gray-700 dark:text-gray-300 transition-colors duration-300">
          IA4u responde hasta el 70% de las consultas de tus clientes sin
          intervención humana. Convierte más leads, brinda soporte excepcional y
          potencia tus ingresos con nuestra solución revolucionaria de atención
          al cliente impulsada por IA.
        </p>
        <div className="space-y-4 md:space-y-0 md:space-x-4 flex flex-col md:flex-row justify-center md:justify-start">
          <button className="bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-700 transition-colors duration-300">
            Comienza gratis
          </button>
          <button className="bg-gray-700 text-gray-200 px-6 py-3 rounded-lg hover:bg-gray-600 transition-colors duration-300">
            Contacta ventas
          </button>
        </div>
      </div>

      {/* Columna de la animación Lottie */}
      <div className="flex justify-center md:justify-center max-w-full">
        <Lottie
          loop
          animationData={darkMode ? animationDataWhite : animationData}
          play
          className="w-full max-w-[200px] sm:max-w-[350px] md:max-w-[400px] lg:max-w-[450px] h-auto"
        />
      </div>
    </div>
  </section>
);

export default HeroSection;
