import React, { useState, useEffect } from 'react';
import logo from '../img/logo-IA4u-w.svg';
import logob from '../img/logo-IA4u-b.svg';
import { BiSolidSun, BiSolidMoon } from 'react-icons/bi';
import { BsFillMoonStarsFill } from 'react-icons/bs';

const Header = ({ darkMode, toggleDarkMode }) => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 60); // Cambiado a 60px para evitar el parpadeo
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header
      className={`sticky top-0 z-50 bg-gray-200 dark:bg-gray-900 text-white transition-all duration-300 ${
        isScrolled ? 'py-1 sm:py-2' : 'py-2 sm:py-4'
      }`}
    >
      <div className="max-w-7xl mx-auto px-6 flex items-center justify-between">
        {/* Sección Izquierda: Logo */}
        <div className="flex-shrink-0">
          <img
            src={logo}
            alt="Logo Claro"
            className={`transition-all duration-300 ${
              isScrolled ? 'w-16' : 'w-20'
            } dark:block hidden`}
            draggable="false"
          />

          {/* Logo para modo claro */}
          <img
            src={logob}
            alt="Logo Oscuro"
            className={`transition-all duration-300 ${
              isScrolled ? 'w-16' : 'w-20'
            } dark:hidden block`}
            draggable="false"
          />
        </div>

        {/* Sección Derecha: Interruptor de Dark Mode */}
        <div className="flex items-center">
          <button
            onClick={toggleDarkMode}
            className="flex items-center justify-center w-10 h-7 rounded-full focus:outline-none transition-colors duration-300"
            aria-label="Toggle Dark Mode"
          >
            {darkMode ? (
              <BiSolidSun className="text-yellow-300 w-6 h-6" />
            ) : (
              <BsFillMoonStarsFill className="text-gray-900 dark:text-gray-100 w-5 h-5" />
            )}
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
