// src/App.js
import React, { useState, useEffect } from 'react';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import Technologies from './components/Technologies';
import Features from './components/Features';
import CallToAction from './components/CallToAction';
import ContactForm from './components/ContactForm';
import Footer from './components/Footer';
import Chatbot from './components/Chatbot';

function App() {
  const [darkMode, setDarkMode] = useState(true); // Iniciar en modo oscuro por defecto

  // Detectar preferencia inicial desde localStorage
  useEffect(() => {
    const savedMode = localStorage.getItem('darkMode');
    if (savedMode !== null) {
      setDarkMode(savedMode === 'true');
    }
    // Si no hay preferencia guardada, ya está en modo oscuro por defecto
  }, []);

  // Aplicar o remover la clase 'dark' en el elemento raíz y guardar la preferencia
  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
    localStorage.setItem('darkMode', darkMode);
  }, [darkMode]);

  const toggleDarkMode = () => setDarkMode((prevMode) => !prevMode);

  return (
    <div className="font-sans antialiased bg-white dark:bg-gray-900 transition-colors duration-300">
      <Header darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
      <HeroSection darkMode={darkMode} />
      <Technologies />
      <Features />
      <CallToAction />
      {/* <ContactForm /> */}
      <Footer />
      <Chatbot />
    </div>
  );
}

export default App;
