// src/components/Technologies.js
import React from 'react';
import { FaReact, FaNodeJs, FaPython } from 'react-icons/fa';
import { SiMongodb, SiTailwindcss } from 'react-icons/si';

const Technologies = () => {
  return (
    <section
      id="technologies"
      className="pt-14 px-4 bg-white dark:bg-gray-900 transition-colors duration-300"
    >
      <div className="max-w-6xl mx-auto text-center">
        <p className="md:text-xl mb-4 text-black dark:text-white transition-colors duration-300">
          Empowering Technologies
        </p>
        <div className="flex flex-wrap justify-center items-center space-x-6">
          {/* React */}
          <div className="m-4">
            <FaReact className="text-black dark:text-white mx-auto text-4xl md:text-6xl transition-colors duration-300" />
          </div>
          {/* Node.js */}
          <div className="m-4">
            <FaNodeJs className="text-black dark:text-white mx-auto text-4xl md:text-6xl transition-colors duration-300" />
          </div>
          {/* MongoDB */}
          <div className="m-4">
            <SiMongodb className="text-black dark:text-white mx-auto text-4xl md:text-6xl transition-colors duration-300" />
          </div>
          {/* Tailwind CSS */}
          <div className="m-4">
            <SiTailwindcss className="text-black dark:text-white mx-auto text-4xl md:text-6xl transition-colors duration-300" />
          </div>
          {/* Python */}
          <div className="m-4">
            <FaPython className="text-black dark:text-white mx-auto text-4xl md:text-6xl transition-colors duration-300" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Technologies;
