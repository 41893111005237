// src/components/CallToAction.js
import React from "react";
import { FaCheckCircle } from "react-icons/fa";

const CallToAction = () => (
  <section className="max-w-5xl mx-4 sm:mx-4 lg:mx-auto my-16 md:my-32 px-4 py-8 md:py-12 bg-gradient-to-r from-blue-600 to-indigo-300 bg-[length:400%_400%] animate-gradient text-center rounded-2xl">
    <div className="mx-auto transition-opacity duration-1000 opacity-100 transform translate-y-0">
      <h3 className="text-2xl md:text-4xl font-semibold mb-4 md:mb-6 text-white">
        ¡Sé el Primero en Enterarte!
      </h3>
      <p className="text-base md:text-lg mb-4 md:mb-6 text-white">
        Suscríbete a nuestra lista de espera y recibe actualizaciones sobre el
        lanzamiento de IA4u.
      </p>
      <form className="flex flex-col md:flex-row items-center justify-center gap-4 mb-4">
        <input
          type="email"
          placeholder="Ingresa tu correo electrónico"
          className="p-3 w-full md:w-72 rounded-lg focus:outline-none"
        />
        <button
          type="submit"
          className="w-full md:w-auto p-3 px-6 rounded-lg bg-white text-blue-600 font-medium hover:bg-opacity-80 transition"
        >
          Suscribirme
        </button>
      </form>

      {/* sección de textos adicionales con iconos de check */}
      <div className="flex flex-col md:flex-row items-center justify-center space-y-2 md:space-y-0 md:space-x-8 text-sm text-gray-200">
        <div className="flex items-center gap-2">
          <FaCheckCircle />
          <span>Atención al cliente dedicada</span>
        </div>
        <div className="flex items-center gap-2">
          <FaCheckCircle />
          <span>GDPR & CCPA-compliant</span>
        </div>
      </div>
    </div>
  </section>
);

export default CallToAction;
